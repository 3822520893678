<template>
  <div class="layout__wrap">
    <el-header class="header__wrap" height="68px">
      <div class="logo__wrap">
        <img class="logo" src="@/assets/img/logo2.png" alt="">
        <div class="portMarking">
          机构端
        </div>
      </div>
      <div class="avatar__wrap">
        <div style="display:flex;align-items:center;">
          <el-badge slot="reference" :value="msgNum" :max="99" class="item">
            <img class="msg" src="@/assets/img/msg.png" alt="">
          </el-badge>
          <img class="default" src="@/assets/img/default.png" alt="">
          <span>{{userName}}</span>
        </div>

        <el-dropdown @command="handleCommand">
          <i class="el-icon-caret-bottom" style="color:#fff;padding:10px;"></i>
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item command="pass">修改密码</el-dropdown-item> -->
            <el-dropdown-item command="logout" divided @click="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
   
    <div class="form__wrap" v-loading="loading" element-loading-text="图片上传中…">
      <div class="top">机构认证</div>

      <!-- company_id == 0,未提交过审核,(只要提交过一次审核就会有企业id了) -->
      <template v-if="company_id == 0">
        <el-form
          class="staff-from"
          ref='elFormDom'
          label-width='110px'
          label-position='top'
          :model='form.data'
          :rules='form.rules'
          size="mini"
          hide-required-asterisk
        >
          <el-row class="licence_wrap">
            <el-col :span="6" style="width:200px;">
              <div class="label" style="margin-bottom:10px">营业执照</div>
              <el-upload
                class="upload-demo"
                action=""
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
                :on-remove="handleRemove"
                :http-request="httpRequest"
                accept="image/jpeg, image/png, image/gif">
                <img v-if="form.data.business_license" :src="form.data.business_license" style="width:160px;height:160px;border-radius:6px;">
                <div v-else style="position: relative;">
                  <img style="160px;height:160px;object-fit: cover;" :src="require('@/assets/img/upload2.png')" />
                  <span class="imgtip">请先上传清晰且未遮挡的营业执照图片</span>
                </div>
                <div  slot="tip" class="el-upload__tip imghint">支持jpg/jpeg/png格式文件 大小不超过5M</div>
              </el-upload>
            </el-col>
            <el-col :span="14">
              <el-row :gutter="30">
                <el-col :span="24">
                  <el-row :gutter="30">
                    <el-col :span="12">
                      <el-form-item label="" prop="name">
                        <span slot="label" class="label">机构名称</span>
                        <el-input
                          v-model="form.data.name"
                          placeholder="自动获取营业执照信息"
                          :disabled="true">
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="" prop="legal_person">
                    <span slot="label" class="label">法人姓名</span>
                    <el-input
                      v-model="form.data.legal_person"
                      placeholder="自动获取营业执照信息"
                      :disabled="true">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="" prop="organization_code">
                    <span slot="label" class="label">组织机构代码</span>
                    <el-input
                      v-model="form.data.organization_code"
                      placeholder="自动获取营业执照信息"
                      :disabled="true">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="" prop="area_arr">
                    <span slot="label" class="label">注册地址</span>
                    <el-row :gutter="10">
                      <el-col :span="12">
                        <el-cascader
                          style="width:100%;"
                          :popper-append-to-body="false"
                          popper-class="areaCascaderWrap"
                          v-model="form.data.area_arr"
                          :options="areaArr"
                          :props="{ checkStrictly: false }">
                        </el-cascader>
                      </el-col>
                      <el-col :span="12">
                        <el-input
                          v-model="form.data.address"
                          placeholder="自动获取营业执照信息"
                          :disabled="true">
                        </el-input>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="7" >
              <el-form-item label="机构简称" prop="simple_name">
                <el-input
                  v-model="form.data.simple_name"
                  placeholder="请输入机构简称，用于展示在产品详情中"
                  clearable>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="行业类型" prop="industry">
                <el-input
                  v-model="form.data.industry"
                  placeholder="请输入所属的行业类型"
                  clearable>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="机构规模（人）" prop="max_contain_count">
                <el-input
                  v-model="form.data.max_contain_count"
                  placeholder="请输入最大容纳人数"
                  @input="form.data.max_contain_count=form.data.max_contain_count.replace(/[^\d^\.]/g,'')"
                  clearable
                  >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="联系人" prop="contact">
                <el-input
                  v-model="form.data.contact"
                  placeholder="请输入联系人"
                  clearable>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="联系电话" prop="contact_mobile">
                <el-input
                  v-model="form.data.contact_mobile"
                  maxlength="11"
                  placeholder="请输入联系人手机号码"
                  clearable>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="14">
              <el-form-item label="机构简介" prop="description">
                <el-input
                  v-model="form.data.description"
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 8}"
                  placeholder="请输入机构简介"
                  clearable
                  maxlength="500"
                  show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="场地照片" prop="space_url" class="imageWrap">
                <template slot="label">场地照片</template>
                <span class="label-hint">（至少上传1张，限9张）</span>
                <el-upload
                  action=""
                  :limit="9"
                  list-type="picture-card"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                  :file-list="fileList"
                  :http-request="httpRequestBack"
                  accept="image/jpeg, image/png, image/gif">
                  <img style="104px;height:104px;object-fit: cover;" :src="require('@/assets/img/upload.png')" />
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="">
                </el-dialog>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" justify="center">
            <div class="confirmBtn" @click="confirm">提交审核</div>
          </el-row>
        </el-form>
      </template>

      <!-- 审核中 -->
      <div class="height620" v-if="company_id != 0 && company_status == 0">
        <div>
          <img  style="width:282px;height:196px;margin-top:50px;" src="@/assets/img/org-status1.png" alt="" >
          <div style="font-size:24px;color: #F99910;margin:40px 0 24px;">认证信息正在审核中</div>
          <div style="font-size:16px;color: #595959;margin-bottom:40px;">请确保您提供的认证信息真实有效，我们会在
            <span style="color:#F99910;">1-3个工作日</span>内完成审核，请耐心等待~
          </div>
        </div>
      </div>

      <!-- 未通过审核 -->
      <div class="height620" v-else-if="company_id != 0 && company_status == 2">
        <div>
          <img  style="width:282px;height:196px;margin-top:50px;" src="@/assets/img/org-status2.png" alt="" >
          <div style="font-size:24px;color: #EC1919;margin:40px 0 24px;">认证信息未通过审核</div>
          <div style="font-size:16px;color: #595959;margin-bottom:40px;">{{check_remark}}</div>
          <el-button type="primary" style="width:160px;" @click="handleEdit">重新编辑</el-button>
        </div>
      </div>
       
    </div>
  </div>
</template>

<script>
import { getStorage, clear } from "@/storage"
import { REG_EXP } from '@/util'
export default {
  name: 'FirstIndex',
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'));
      } else {
        if (this.form.data.checkPass !== '') {
          this.$refs.elFormDom.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.form.data.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      msgNum: '',
      visible: false,
      loading: false,
      isCollapse: false,
      toggleMenu: false,
      userName: getStorage('userName') || 'admin',
      ruleForm: {
        login: false,
        data: {
          password: ''
        },
        rules: {
          password: [
            { required: true, validator: validatePass, trigger: 'blur' }
          ],
          checkPass: [
            { required: true, validator: validatePass2, trigger: 'blur' }
          ],
        }
      },
      form: {
        data: {
          business_license: '', //营业执照
          name: '',//机构名称
          legal_person: '',//法人姓名
          organization_code: '',//组织机构代码
          area_arr: [],
          province: 0, //注册地址省
          city: 0, //注册地址市
          area: 0, //注册地址区
          address: "", //地址
          simple_name: "", //机构简称
          industry: "", //行业类型
          max_contain_count: "", //最大容纳人数
          contact: "", //联系人
          contact_mobile: "", //电话
          description: "", //机构简介
          space_url: "" //场地照片，用,分割
        },
        rules: {
          business_license: [{ required: true, message:'必填项', trigger: 'change' }],
          name: [{ required: true, message:'必填项', trigger: 'change' }],
          legal_person: [{ required: true, message:'必填项', trigger: 'change' }],
          organization_code: [{ required: true, message:'必填项', trigger: 'change' }],
          area_arr: [{ required: true, message:'必填项', trigger: 'change' }],
          address: [{ required: true, message:'必填项', trigger: 'change' }],
          simple_name: [{ required: true, message:'必填项', trigger: 'change' }],
          industry: [{ required: true, message:'必填项', trigger: 'change' }],
          max_contain_count: [{ required: true, message:'必填项', trigger: 'change' }],
          contact: [{ required: true, message:'必填项', trigger: 'change' }],
          contact_mobile: [
            { required: true, message:'必填项', trigger: 'change' },
            { pattern: REG_EXP.mobilePhone, message: "手机号不正确", trigger: 'blur' }
          ],
          description: [{ required: true, message:'必填项', trigger: 'change' }],
          space_url: [{ required: true, message:'必填项', trigger: 'change' }],
        },
      },
      dialogImageUrl: [],
      dialogVisible: false,
      fileList: [],
      areaArr: [],
      status: 2,
      company_id: 0,
      company_status: 0,
      check_remark:'',
    }
  },
  created() {
    this.getIndex();
    this.getArea();
  },
  methods: {
    // 获取机构认证状态
    getIndex() {
      this.$http.get('/company/index/index', {params:{}}).then(res => {
        if(res.code == 1) {
          this.company_id = res.data.company_id; //机构ID 0表示未认证机构
          this.company_status = res.data.status; //状态：0:审核中  1审核通过  2审核不通过
          this.check_remark = res.data.check_remark;  // 审核未通过的原因
        }
      })
    },
    // 获取机构信息
    getInfo() {
      this.$http.get('/company/index/info', {params:{}}).then(res => {
        this.form.data = res.data;
        this.form.data.area_arr = [res.data.province,res.data.city,res.data.area];
        if(this.form.data.space_url.length > 0) {
          this.fileList = []
          this.form.data.space_url.forEach(v => {
            let str = {
              name: '',
              url: v
            }
            this.fileList.push(str)
          })
        }
      })
    },
    // 获取省市区
    getArea() {
      this.$http.post('/common/district/all',{}).then(res => {
        if(res.code == 1) {
          this.areaArr = res.data.list;
        }
      })
    },
    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error('上传文件大小不能超过 5MB!');
      }
      return isLt5M;
    },
    async httpRequest(file) {
      let formData = new FormData();
      formData.append('file', file.file);
      this.loading = true;
      await this.$http.post('/common/qiniu/upload', {formData, type:'upload'}).then(res => {
        this.form.data.business_license = res.data.all_path_url;
        this.$refs.elFormDom.clearValidate();
      }).finally(() => {
        this.loading = false;
      })
      // 识别营业执照
      await this.$http.post('/common/baidu/license', {url: this.form.data.business_license}).then(res => {
        console.log(res.data)
        this.form.data.name = res.data.name;
        this.form.data.legal_person = res.data.legal;
        this.form.data.organization_code = res.data.credit_code;
        this.form.data.address = res.data.address;
      }).finally(() => {
        this.loading = false;
      })
    },
    // 上传
    httpRequestBack(file) {
      let formData = new FormData();
      formData.append('file', file.file);
      this.$http.post('/common/qiniu/uploadFile', {formData, type:'upload'}).then(res => {
        if(res.code === 1) {
          let str = {
            url: res.data.all_path_url,
            name: file.file.name,
            uid: file.file.uid
          }
          this.fileList.push(str);
          this.form.data.space_url = res.data.all_path_url;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file) {
      this.fileList.forEach((v,i) => {
        if(file.uid == v.uid) {
          this.fileList.splice(i, 1)
        }
      })
    },
    confirm() {
      this.$refs.elFormDom.validate(valid => {
        if(valid) {
          let _params = {...this.form.data}
          if(this.fileList.length > 0){
            let _image = []
            this.fileList.forEach(v => {
              _image.push(v.url)
            })
            _params.space_url = _image.join(",")
          } else {
            _params.space_url = '';
          }
          _params.province = this.form.data.area_arr[0];
          _params.city = this.form.data.area_arr[1];
          _params.area = this.form.data.area_arr[2];
          delete _params.area_arr;
          this.$http.post('/company/index/cert', _params).then(res => {
            if(res.code == 1) {
              this.$message.success('提交成功！')
              this.getIndex();
            }
          })
        }
      })
    },
    // 审核失败重新编辑
    handleEdit() {
      this.company_id = 0;
      this.getInfo(); // 获取上次提交的机构信息
    },
    handleCommand(command) {
      if(command === 'logout') {
        this.logout();
      } else {
        this.visible = true;
      }
    },
    // 退出登录
    logout() {
      this.$confirm("请确认是否退出登录？", "提示", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            clear(); // 删除token
            window.location.assign('/login')
            done()
          } else {
            done()
          }
        }
      }).catch(err => {})
    },
    // 修改密码
    submitForm() {
      this.$refs.elFormDom.validate(valid => {
        if(valid) {
          this.$http.post('admin/flex_index/updatePassword', {password: this.form.data.password}).then(res => {
            if(res.code === 1) {
              this.$message.success('操作成功！')
              this.visible = false;
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
  }
}
</script>
<style scoped lang="scss">
.layout__wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: #F2F5FC;
  overflow: hidden;
}

.header__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $theme;
  // background-image: linear-gradient(to right, $theme, #0bCFFF);
  .logo__wrap {
    display: flex;
    align-items: center;
    .logo {
      width: 129px;
      height: 30px;
    }
    .logoText {
      color: #fff;
      font-size: 20px;
      font-weight: bold;
    }
    .J_menutoggle {
      color: #fff;
      margin-left: 20px;
      display: none;
    }
  }
  .avatar__wrap {
    color: #fff;
    display: flex;
    align-items: center;
    
    .userName {
      margin-right: 20px;
    }
    .exit {
      cursor: pointer;
      display: flex;
      align-items: center;
    }
    .item {
      text-align: center;
      cursor: pointer;
    }
  }
  .default {
    margin: 0 10px 0 30px;
    width: 32px;
    height: 32px;
  }
  .msg {
    width: 30px;
    height: 30px;
  }
}

.form__wrap {
  width: 1000px;
  margin:20px auto;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  overflow: auto;
  padding-bottom: 20px;
   
  &::v-deep {
    .el-card__body {
      padding: 0;
    }
  }
}
.top {
  height: 50px;
  line-height: 50px;
  padding: 0 33px;
  border-bottom: solid 1px #eee;
  font-size: 20px;
  color: #333;
}

.licence_wrap {
  background: #FCFCFC;
  border: solid 1px #E5E5E5;
  padding: 20px;
  border-radius: 3px;
  margin-bottom: 10px;
}
.imageWrap ::v-deep .el-upload--picture-card {
  border: none;
  width: 104px;
  height: 104px;
}
.imageWrap ::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 104px;
  height: 104px;
}
.imageWrap ::v-deep .el-range-editor--small .el-range-separator {
  width: 20px;
}
.staff-from::-webkit-scrollbar {
  display: none;
}
.staff-from {
  padding: 20px 32px;
  overflow-y: scroll;
}
.label {
  color: #999999;
  font-size: 14px;
}
.label-hint {
  font-size: 14px;
  color: rgb(153, 153, 153);
  position: absolute;
  top: -33px;
  left: 62px;
}
.imghint {
  width: 160px;
  font-size: 12px;
  color: #8C8C8C;
  text-align: center;
  line-height: 20px;
}
.imgtip {
  font-size: 12px;
  color: rgb(243, 81, 73);
  bottom: 26px;
  position: absolute;
  left: 10px;
  width: 130px;
}
.confirmBtn {
  width: 300px;
  height: 50px;
  background: #7FBEF8;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  margin: 0 auto;
  cursor: pointer;
  margin: 20px auto 0;
}
.height620 {
  height: 620px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.portMarking {
  width: 90px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  margin-left: 55px;
  color: #836117;
  font-weight: bold;
  background-image: url('~@/assets/img/sbg.png');
  background-size:90px 36px;
  background-repeat: no-repeat;
}
</style>
